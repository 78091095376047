import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container, Col, Row } from "react-bootstrap"

import upperright from "../images/muto1.png";
import bottomleft from "../images/muto2.jpg";

export default function Services({location}) {
  return (
    <Layout lang='de' location={location}>
      <SEO lang='de' title="Leistungen"/>
      <Container fluid className="h-100" style={{backgroundColor: "var(--primary-color)"}}>
        <Row style={{minHeight: "60vh"}} >
          <Col className="align-self-center">
            <Container className="text-center leistungen-text" style={{paddingTop: 40, paddingBottom: 40}}>
              <p>Erkrankungen, welche vor allem die Notwendigkeit einer chirurgischen Operation mit sich bringen, sind oft mit Unsicherheit und Angst verbunden. 
              Für eine optimale persönliche Betreuung oder erfolgreiche Intervention, ist es wichtig, ausreichend Zeit vor und nach dem Eingriff für alle offene Fragen zu haben. 
              Die Privatordination bietet eine vertrauensvolle, ruhige Atmosphäre vom Erstgespräch bis zur letzen Befundbesprechung und Nachkontrolle. </p>
            </Container>
          </Col>
          <Col className="muto1" style={{backgroundImage: `url(${upperright})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}/>
        </Row>
        <Row style={{minHeight: "60vh"}}>
          <Col className="muto2" style={{backgroundImage: `url(${bottomleft})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}/>
          <Col className="align-self-center">
            <Container className="text-center leistungen-text" style={{paddingTop: 40, paddingBottom: 40}}>
              <p>Ich führe die notwendigen Operationen im A.ö. Krankenhaus Oberpullendorf, im Rahmen einer durch die Krankenversicherung finanzierten Versorgung, selbst durch.</p>
              <p>Ich biete Ihnen das volle allgemeinchirurgische Leistungsspektrum an:</p>
              <p className="leistungen-item"><b>Gallenblase/Gallengang</b></p>
              <p className="leistungen-item"><b>Leistenhernie - TAPP</b></p>
              <p className="leistungen-item"><b>Nabelhernie/Narbenhernie - eTEP/eMILOS</b></p>
              <p className="leistungen-item"><b>Dünndarm/Dickdarm</b></p>
              <p className="leistungen-item"><b>Magen</b></p>
              <p className="leistungen-item"><b>Hauttumoren</b></p>
              <p className="leistungen-item"><b>Proktologie Hämorrhoiden - DGHAL</b></p>
              <p style={{marginTop: '1rem'}}>Wenn immer es möglich ist, kommt die minimalinvasive Technik mit den modernsten laparoskopischen Methoden zum Einsatz. Durch die Privatordination ist eine unverzügliche und unkomplizierte Terminisierung, sowie Beratung in ruhiger Atmosphäre möglich.</p>
            </Container>
          </Col>
          
        </Row>
      </Container>
    </Layout>
  )
}
